<template>
  <div>
<!--    https://codepen.io/jgthms/pen/EjxBdR-->
    <div id="report-print-orientation-portrait" style="display: none">
      @page {
        size: A4 portrait;
        margin: 0.6cm 0.5cm;
      }

      html, body {
        width: 2480px;
        height: 3508px;
        margin: 0;
        padding: 0;
        font-family: Helvetica;
      }
    </div>

    <div id="report-print-orientation-landscape" style="display: none">
      @page {
        size: A4 landscape;
        margin: 0.6cm 0.5cm;
      }

      html, body {
        height: 2480px;
        width: 3508px;
        margin: 0;
        padding: 0;
        font-family: Helvetica;
      }
    </div>

    <div id="report-print-style" style="display: none">
          .main-wrapper {
            font-family: Helvetica;
            padding: 0;
            width: inherit;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
          }

          .title-wrapper {
            text-align: center;
            font-weight: bold;
            margin-bottom: 60px;
            flex-basis: 100%
          }

          .report-title{
            font-size: 42px;
            text-transform: uppercase;
          }

          .report-sub-title {
            font-size: 42px;
            text-transform: uppercase;
          }

          .table-wrapper {
            margin-top: 70px
            padding: 0;
            text-align:left;
          }

          .table-for-printing {
            -webkit-print-color-adjust: exact;
            text-align:left;
          }

          .table-for-printing tr:nth-child(even) td div {
            background-color: #c8c8c8;
          }

          .table-to-print-body-tr {
            font-size: 28px;
            color: #000;
          }

          .table-head-th {
            font-size: 36px;
            margin-right: 20px;
            padding-bottom: 6px;
            border-bottom: 2px solid #000;
          }

          .content-cell {
            margin-right: 5px !important;
            font-size: 40px;
            padding: 5px;
            height: 50px;
          }

          .province-label-cell {
            margin-left: 20px;
            font-size: 42px;
          }

          .table-for-printing tbody:not(:nth-child(2)) tr:first-child {
            height: 80px;
            vertical-align: bottom;
          }

          .bg-warning {
              background-color: #ffc107 !important;
          }
    </div>


    <div style="position: absolute;right: 16px;top:10px;z-index: 12;">
      <a @click="print" class="x-button x-button-yellow">
        <i class="el-icon-printer" style="font-size: 14pt;"></i>
        Print <strong>report</strong>
      </a>

      <a @click="closeDialog" class="x-button x-button-orange">
        <i class="el-icon-close" style="font-size: 14pt;"></i>
        Exit
      </a>
    </div>

    <div id="report-to-print-type-2" v-if="reportType == 2">
      <div class="main-wrapper">
        <div class="title-wrapper">
          <div contenteditable="true" class="report-title">{{ title }}</div>
          <div contenteditable="true" class="report-sub-title">{{ subTitle }}</div>
        </div>

        <div class="table-wrapper" style="flex-basis: 100%">

          <div v-for="(section, sKey) in reportData" style="margin-bottom: 50px;padding: 0;width: 100%">
            <div style="border-bottom: 1px solid #a0aac0" :style="section.province.cssStyle">{{section['province'].label}}</div>
            <div class="table-to-print-body-tr" v-for="(data, dkey) in section.data" style="display: inline-block">
              <div v-for="(cell, ckey) in reportColumns" style="display: inline-block;font-size: 36px">
                {{ fillReportCell(cell, data) }}
              </div>
              <span style="font-size: 36px;margin-right: 20px;">, </span>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div id="report-to-print-type-1" v-if="reportType == 1">
      <div class="main-wrapper">
        <div class="table-wrapper">

          <div class="title-wrapper">
            <div contenteditable="true" class="report-title">{{ title }}</div>
            <div contenteditable="true" class="report-sub-title">{{ subTitle }}</div>
          </div>

          <table class="table-for-printing">
            <thead>
            <tr>
              <th :style="'width:' + col.width + 'px'" contenteditable="true" v-for="(col, key) in reportColumns" style="padding: 0">
                <div class="table-head-th" contenteditable="true" :style="(col.hasOwnProperty('headStyle')? col.headStyle : '' )">
                  <span v-if="col.hasOwnProperty('label')">{{ col.label }}</span>

                  <img :src="'/portals/cpanel/icons/' + col.local_icon + '.png'"
                       :width="col.icon_width" style="position: relative; top: -1px;"
                       v-if="col.hasOwnProperty('local_icon')"/>
                  <img :src="'/img/flags/' + col.img" v-if="col.hasOwnProperty('img')" :width="col.img_width"/>
                </div>
              </th>
            </tr>
            </thead>
            <tbody v-for="(section, sKey) in reportData" style="margin-bottom: 50px;padding: 0;width: 100%">
            <tr>
              <th>
                <div :style="section.province.cssStyle">{{section['province'].label}}</div>
              </th>
            </tr>
            <tr class="table-to-print-body-tr" v-for="(data, dkey) in section.data">
              <td v-for="(cell, ckey) in reportColumns" :style="'position: relative; width:' + cell.width + 'px;' + (cell.hasOwnProperty('tdStyle')? cell.tdStyle : '' )">
                <div :class="reportCellCustomClass(cell, data)" class="content-cell" :style="'width:' + cell.width + 'px;' + (cell.hasOwnProperty('style')? cell.style : '' )" contenteditable="true">
                  {{ fillReportCell(cell, data) }}
                </div>
                <p v-if="cell.hasOwnProperty('defaultValueOutSide') && cell.showDefaultValueOutSide(cell, data)" :style="'position: absolute;margin: 0 !important;bottom: 0px;left: 5px;' + (cell.hasOwnProperty('defaultValueOutSideStyle')? cell.defaultValueOutSideStyle : '' )">{{cell.defaultValueOutSide }}</p>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {chunkArray} from "devegram-javascript-util";

export default {
  props: {
    reportType: {
      default: 1,
    },
    title: {
      required: true
    },
    subTitle: {
      required: true
    },
    reportColumns: {
      required: true
    },
    reportData: {
      required: true
    },
    reportCellCustomClass: {
      default: (a ,b) => {
        return ''
      }
    },
    printPageWidth: {
      default: true
    },
    orientation: {
      default: 'portrait'
    }
  },
  mounted() {
    /*let container = document.getElementById('report-to-print')
    container.style.cssText = document.getElementById('report-print-style').innerText + " " + document.getElementById('report-print-orientation-' + this.orientation).innerText*/
  },
  methods: {
    openLabelLayout () {
      // https://github.com/bpampuch/pdfmake
      // https://pdfmake.github.io/docs/0.1/document-definition-object/page/
      // http://pdfmake.org/playground.html

      //this.dialogVisible = true
      let pdfMake = require('pdfmake/build/pdfmake.js');
      let pdfFonts = require('pdfmake/build/vfs_fonts.js');
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let currentYear = new Date().getFullYear();
      let currentMonthName = new Date(currentYear, this.selectedMonth, 1).toLocaleString('default', {month: 'short'});


      let labels = []
      let data = this.getSelectedClientsIds()

      let chunkedData = chunkArray(data, 2);

      let row = []
      for(let chunk in chunkedData) {
        row = []
        for(let client in chunkedData[chunk]) {

          let t1 = {text: chunkedData[chunk][client].business_name +  '\n', fontSize: 13, bold: true}
          let t3 = {text: ''+ (chunkedData[chunk][client].tax_hst_reporting_method_label).toString().replace('fi', 'f i') + '\n\n', style: 'accountingType'}
          let t4 = {text: 'Missing any: \n\n', style: 'dashedSeparator'}
          let t5 = {text: 'Bank Statement: Yes___  Sales Yes___\n\n', style: 'dashedSeparator'}
          let t6 = {text: currentMonthName + ' ' + currentYear, fontSize: 21, style: 'dashedSeparator'}

          let layout = {
            text:  [t1, t3, t4, t5, t6],
            alignment: 'center'
          }

          /*if (labels.length != 0 && (labels.length) % 5 == 0) {
            layout.pageBreak = 'before'
          }*/

          row.push(layout)
        }

        let rows = []
        rows.push(row)
        labels.push({
          style: 'tableExample',
          table: {
            headerRows: false,
            widths: [289, 289],
            heights: 145,
            body: rows
          },
          layout: 'noBorders'
        })
      }

      let counter = 0
      pdfMake.createPdf({
        pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (previousNodesOnPage.length == 16) return true
          return false;
        },
        // a string or { width: number, height: number }
        pageSize: 'A4',

        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 11, 28, 11, 28],
        styles: {
          tableExample: {
            align:'center'
          },
          accountingType: {
            characterSpacing: 4
          },
          dashedSeparator: {
            lineHeight: .7
          }
        },
        defaultStyle: {
          columnGap: 20
        },
        content: labels
      }).print();
    },
    closeDialog () {
      this.$emit('close')
    },
    fillReportCell(cell, data) {
      let v = null
      if (cell.from == 'empty') v =  ''
      if (cell.from == 'api') v = data[cell.prop]
      if (cell.from == 'local') v = this[cell.prop]
      if (cell.from == 'func') v = cell.func(cell, data);
      if ((v == '' || v == ' ') && cell.hasOwnProperty('defaultValue')) v = cell.defaultValue

      return v
    },
    print() {
      let dom = document.getElementById('report-to-print-type-' + this.reportType);
      let mywindow = window.open('', 'PRINT', 'height=750,width=800');
      let style = document.getElementById('report-print-style').innerText
      let sizeProperties = document.getElementById('report-print-orientation-' + this.orientation).innerText
      //alert('@media print {'+ sizeProperties + ' ' + style +'}')
      let htmlDocument = '<html><head></head><style>@media print {'+ sizeProperties + ' ' + style +'}</style><body>'+ dom.innerHTML + '</body></html>'
      mywindow.document.write(htmlDocument);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
      return true;
    },
  }
}
</script>

<style scoped>
.main-wrapper {
  border-right: 1px solid #000;
  transform: scale(0.35) translateX(-93%) translateY(-95%);
  font-family: Helvetica;
  padding: 0;
  width: 3508px;
  margin: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.title-wrapper {
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-basis: 100%;
 }

.report-title{
  font-size: 42px;
  text-transform: uppercase;
}

.report-sub-title {
  font-size: 42px;
  text-transform: uppercase;
}

.table-wrapper {
  margin-top: 70px;
  padding: 0;
  text-align:left;
}

.table-for-printing {
  -webkit-print-color-adjust: exact;
  text-align:left;
}

.table-for-printing tr:nth-child(even) td div {
  background-color: #c8c8c8;
}

.table-to-print-body-tr {
  font-size: 28px;
  color: #000;
}

.table-head-th {
  font-size: 36px;
  margin-right: 20px;
  padding-bottom: 6px;
  border-bottom: 2px solid #000;
}

.content-cell {
  margin-right: 5px !important;
  font-size: 40px;
  padding: 0px 5px 10px 5px;
  height: 50px;
}

.province-label-cell {
  margin-left: 20px;
  font-size: 42px;
}

.table-for-printing tbody:not(:nth-child(2)) tr:first-child {
  height: 80px;
  vertical-align: bottom;
}

.bg-warning {
  background-color: #ffc107 !important;
}
</style>
